import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'
import components from "@/components"

Vue.use(VueRouter);
const router = new VueRouter({
    routes: [
      {
        path: "/",
        name: "Welkom",
        component: components.Welkom
      },
      {
        path: "/baten-lasten",
        name: "BatenLasten",
        component: components.BatenLasten
      },
      {
        path: "/beleid-organisatie",
        name: "BeleidOrganisatie",
        component: components.BeleidOrganisatie
      },
      {
        path: "/bestuur",
        name: "Bestuur",
        component: components.Bestuur
      },
      {
        path: "/vermogen",
        name: "Vermogen",
        component: components.Vermogen
      }
    ]
});

new Vue({
    router,
    render: function (h) { return h(App) },
  }).$mount('#app')
  